import Image from "next/image";
import Link from "next/link";
import { useEffect } from "react";
import sal from "sal.js";

import "venobox/dist/venobox.min.css";

import videoImg1 from "../../public/images/gallery/enrollment1.jpg";
import videoImg from "../../public/images/others/video-06.jpg";
import CardThree from "../Cards/Card-Three";
import ContactForm from "../Contacts/Contact-Form";
import UniversityBanner from "./UniversityBanner";

import program4 from "../../public/images/blog/blog-bl-01.jpg";
import program3 from "../../public/images/blog/blog-bl-04.jpg";
import program2 from "../../public/images/blog/blog-bl-05.jpg";
import program5 from "../../public/images/blog/blog-bl-06.jpg";
import program6 from "../../public/images/blog/blog-bl-07.jpg";
import program1 from "../../public/images/blog/blog-bl-08.jpg";

const UniversityClassic = () => {
  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
      });
    });

    sal({
      threshold: 0.01,
      once: true,
    });
  }, []);

  return (
    <>
      <div className="rbt-slider-main-wrapper position-relative" style={{overflow: 'hidden'}}>
        <UniversityBanner />
        <video autoPlay muted loop className="videoBanner">
          <source src="/video_banner.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video autoPlay muted loop className="mobileBanner">
          <source src="/video_banner_mobile.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <div
        className="rbt-course-area rbt-section-gapTop rbt-section-gapBottom bg-color-white"
        id="course"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="section-title text-center">
                <h2 className="title">Why Enroll in Our Programs?</h2>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <CardThree />
          </div>
        </div>
      </div>

      <div
        className="rbt-video-area rbt-section-gapTop rbt-section-gapBottom"
        id="about"
        style={{ backgroundColor: "#F4F4F4" }}
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="video-popup-wrapper">
                <Image
                  className="w-100 rbt-radius"
                  src={videoImg}
                  width={638}
                  height={407}
                  property="true"
                  alt="Video Images"
                />
                {/* <Link
                  className="rbt-btn rounded-player popup-video position-to-top rbtplayer"
                  data-vbtype="video"
                  href="https://www.youtube.com/watch?v=nA1Aqp0sPQo"
                >
                  <span>
                    <i className="feather-play"></i>
                  </span>
                </Link> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="inner pl--50 pl_lg--0 pl_md--0 pl_sm--0">
                <div className="section-title text-start">
                  <h4 className="title">Elevate Your Beauty Career with Us!</h4>
                  <p className="description mt--10">
                    Discover a world of beauty and innovation at GD College. Our
                    state-of-the-art facilities, coupled with industry-leading
                    trainers, provide the perfect platform to hone your skills
                    and set prospects for a better career!
                  </p>
                  <p>
                    <Link
                      className={`rbt-btn radius-round btn-sm hover-transform-non`}
                      href="/about"
                    >
                      {/* <span data-text={`${btnText}`}>{btnText}</span> */}
                      <span data-text={`Read more`}>Read more</span>
                    </Link>
                  </p>
                  {/* <div className="rbt-feature-wrapper mt--10">
                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon" style={{ background: "#01515C" }}>
                        <i
                          className="feather-heart"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Flexible Classes</h6>
                      </div>
                    </div>

                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon" style={{ background: "#01515C" }}>
                        <i
                          className="feather-book"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Learn From Anywhere</h6>
                      </div>
                    </div>

                    <div className="rbt-feature feature-style-1 align-items-center">
                      <div className="icon" style={{ background: "#01515C" }}>
                        <i
                          className="feather-monitor"
                          style={{ color: "white" }}
                        ></i>
                      </div>
                      <div className="feature-content">
                        <h6 className="feature-title">Experienced Faculty.</h6>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="rbt-video-area rbt-section-gapTop rbt-section-gapBottom"
        id="enrollment"
        style={{ backgroundColor: "#000" }}
      >
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <div className="video-popup-wrapper">
                <Image
                  className="w-100 rbt-radius"
                  src={videoImg1}
                  width={638}
                  height={407}
                  property="true"
                  alt="Video Images"
                />
              </div>
            </div>

            <div className="col-lg-6">
              <div className="inner pl--50 pl_lg--0 pl_md--0 pl_sm--0">
                <div className="section-title text-start">
                  <h4 className="title" style={{ color: "white" }}>
                     Enrollment
                  </h4>
                  <p className="description mt--10" style={{ color: "white" }}>
                    Join our upcoming batch and unlock your potential
                    in the beauty industry.
                    <br />
                    Enrollment is now open. Register early to secure your place.
                  </p>
                  <p>
                    <a
                      target='_blank'
                      className={`rbt-btn radius-round btn-sm hover-transform-non`}
                      href="https://meetings.hubspot.com/gdcollege/connect"
                    >
                      <span data-text={`Contact Us`}>Book an appointment</span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="rbt-category-area bg-color-white rbt-section-gapTop">
        <ServiceEight isHead={false} />
      </div> */}

      {/* <div className="rbt-banner-area rbt-section-gapTop">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <Swiper
                className="swiper viral-banner-activation rbt-arrow-between"
                slidesPerView={1}
                modules={[Navigation]}
                navigation={{
                  nextEl: ".rbt-arrow-left",
                  prevEl: ".rbt-arrow-right",
                }}
              >
                <div className="swiper-wrapper">
                  <SwiperSlide className="swiper-slide">
                    <div className="thumbnail">
                      <Link href="/programs">
                        <Image
                          className="rbt-radius w-100"
                          src={hiImgOne}
                          width={1305}
                          height={425}
                          property="true"
                          alt="Banner Images"
                        />
                      </Link>
                    </div>
                  </SwiperSlide>

                  {/* <SwiperSlide className="swiper-slide">
                    <div className="thumbnail">
                      <Link href="#">
                        <Image
                          className="rbt-radius w-100"
                          src={hiImgTwo}
                          width={1305}
                          height={425}
                          property="true"
                          alt="Banner Images"
                        />
                      </Link>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide className="swiper-slide">
                    <div className="thumbnail">
                      <Link href="#">
                        <Image
                          className="rbt-radius w-100"
                          src={hiImgThree}
                          width={1305}
                          height={425}
                          property="true"
                          alt="Banner Images"
                        />
                      </Link>
                    </div>
                  </SwiperSlide> 
                </div>
                <div className="rbt-swiper-arrow rbt-arrow-left">
                  <div className="custom-overfolow">
                    <i className="rbt-icon feather-arrow-left"></i>
                    <i className="rbt-icon-top feather-arrow-left"></i>
                  </div>
                </div>
                <div className="rbt-swiper-arrow rbt-arrow-right">
                  <div className="custom-overfolow">
                    <i className="rbt-icon feather-arrow-right"></i>
                    <i className="rbt-icon-top feather-arrow-right"></i>
                  </div>
                </div>
              </Swiper>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="rbt-advance-tab-area rbt-section-gapTop bg-color-white">
        <AdvanceTab
          tag=""
          title="About Us."
          desc=" Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts."
        />
      </div> */}

      <div
        className="rbt-program-area rbt-section-gapTop rbt-section-gapBottom bg-color-white"
        id="program"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="section-title text-center">
                <h2 className="title">Our Programs</h2>
              </div>
            </div>
          </div>

          <div className="rbt-course-grid-column ">
            <div className="course-grid-3">
              <div className="rbt-card variation-01 rbt-hover ">
                <div className="rbt-card-img">
                  <Link href="/massage-therapy">
                    <Image
                      className="w-100 rbt-radius"
                      src={program1}
                      width={638}
                      height={407}
                      property="true"
                      alt="Video Images"
                    />
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top"></div>
                  <h4 className="rbt-card-title">
                    <Link href="/massage-therapy">Massage Therapy</Link>
                  </h4>

                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span
                        className="current-price"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="feather-clock"></i> &nbsp; 2 Years
                      </span>
                    </div>
                    <Link className="rbt-btn-link" href="/massage-therapy">
                      Read More<i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="course-grid-3">
              <div className="rbt-card variation-01 rbt-hover ">
                <div className="rbt-card-img">
                  <Link href="/advanced-esthetics-diploma">
                    <Image
                      className="w-100 rbt-radius"
                      src={program2}
                      width={638}
                      height={407}
                      property="true"
                      alt="Video Images"
                    />
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top"></div>
                  <h4 className="rbt-card-title">
                    <Link href="/advanced-esthetics-diploma">
                      Advanced Esthetics Diploma
                    </Link>
                  </h4>

                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span
                        className="current-price"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="feather-clock"></i> &nbsp; 8 months
                      </span>
                    </div>
                    <Link className="rbt-btn-link" href="/advanced-esthetics-diploma">
                      Read More<i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="course-grid-3">
              <div className="rbt-card variation-01 rbt-hover ">
                <div className="rbt-card-img">
                  <Link href="/clinical-esthetician">
                    <Image
                      className="w-100 rbt-radius"
                      src={program3}
                      width={638}
                      height={407}
                      property="true"
                      alt="Video Images"
                    />
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top"></div>
                  <h4 className="rbt-card-title">
                    <Link href="/clinical-esthetician">Clinical Esthetician</Link>
                  </h4>

                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span
                        className="current-price"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="feather-clock"></i> &nbsp; 5 months
                      </span>
                    </div>
                    <Link className="rbt-btn-link" href="/clinical-esthetician">
                      Read More<i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="course-grid-3">
              <div className="rbt-card variation-01 rbt-hover ">
                <div className="rbt-card-img">
                  <Link href="/esthetician-diploma">
                    <Image
                      className="w-100 rbt-radius"
                      src={program4}
                      width={638}
                      height={407}
                      property="true"
                      alt="Video Images"
                    />
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top"></div>
                  <h4 className="rbt-card-title">
                    <Link href="/esthetician-diploma">Esthetician Diploma</Link>
                  </h4>

                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span
                        className="current-price"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="feather-clock"></i> &nbsp; 5 months
                      </span>
                    </div>
                    <Link className="rbt-btn-link" href="/esthetician-diploma">
                      Read More<i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="course-grid-3">
              <div className="rbt-card variation-01 rbt-hover ">
                <div className="rbt-card-img">
                  <Link href="/makeup-artist">
                    <Image
                      className="w-100 rbt-radius"
                      src={program5}
                      width={638}
                      height={407}
                      property="true"
                      alt="Video Images"
                    />
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top"></div>
                  <h4 className="rbt-card-title">
                    <Link href="/makeup-artist">
                      Makeup Artist & Hair Stylist
                    </Link>
                  </h4>

                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span
                        className="current-price"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="feather-clock"></i> &nbsp; 4 months
                      </span>
                    </div>
                    <Link className="rbt-btn-link" href="/makeup-artist">
                      Read More<i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="course-grid-3">
              <div className="rbt-card variation-01 rbt-hover ">
                <div className="rbt-card-img">
                  <Link href="/nail-technician">
                    <Image
                      className="w-100 rbt-radius"
                      src={program6}
                      width={638}
                      height={407}
                      property="true"
                      alt="Video Images"
                    />
                  </Link>
                </div>
                <div className="rbt-card-body">
                  <div className="rbt-card-top"></div>
                  <h4 className="rbt-card-title">
                    <Link href="/nail-technician">Nail Technician</Link>
                  </h4>

                  <div className="rbt-card-bottom">
                    <div className="rbt-price">
                      <span
                        className="current-price"
                        style={{ fontSize: "16px" }}
                      >
                        <i className="feather-clock"></i> &nbsp; 4 months
                      </span>
                    </div>
                    <Link className="rbt-btn-link" href="/nail-technician">
                      Read More<i className="feather-arrow-right"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row g-5 align-items-end mt--30">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-center">
                <Link className="rbt-btn-link" href="/programs">
                  View All Programs<i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div> */}

          {/* <div className="row g-5">
            {
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rbt-category-gallery">
                  <div className="thumbnail">
                    <Link href="/program-details/15">
                      <Image
                        src={imgOne}
                        property="true"
                        alt="Gallery Images"
                      />
                      <div className="rbt-bg-overlay"></div>
                    </Link>
                    <div className="program-title">
                      <h3 className="title color-white">
                        <Link href="/program-details/15">
                          Esthetician Diploma
                        </Link>
                        <div>
                          {/* <br/>
                        <div style={{'fontSize':'2rem', 'fontWeight':'normal'}}>
                          <i className="feather-calendar"></i> 5 Months (32 hours per week)
                        </div> 
                          {/* <div style={{'fontSize':'2rem', 'fontWeight':'500'}}>
                          C$ 7500
                        </div> 
                        </div>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="/program-details/16">
                    <Image src={imgTwo} property="true" alt="Gallery Images" />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="program-title">
                    <h3 className="title color-white">
                      <Link href="/program-details/16">
                        Clinical Esthetician
                      </Link>
                      <div>
                        {/* <br/>
                        <div style={{'fontSize':'2rem', 'fontWeight':'normal'}}>
                          <i className="feather-calendar"></i> 5 Months (32 hours per week)
                        </div> 
                        {/* <div style={{'fontSize':'2rem', 'fontWeight':'500'}}>
                          C$ 7500
                        </div> 
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="rbt-category-gallery">
                <div className="thumbnail">
                  <Link href="/program-details/35">
                    <Image
                      src={imgThree}
                      property="true"
                      alt="Gallery Images"
                    />
                    <div className="rbt-bg-overlay"></div>
                  </Link>
                  <div className="program-title">
                    <h3 className="title color-white">
                      <Link href="/program-details/35">
                        Advanced Esthetics Diploma
                      </Link>
                      <div>
                        {/* <br/>
                        <div style={{'fontSize':'2rem', 'fontWeight':'normal'}}>
                          <i className="feather-calendar"></i> 5 Months (32 hours per week)
                        </div> 
                         <div style={{'fontSize':'2rem', 'fontWeight':'500'}}>
                          C$ 7500
                        </div> 
                      </div>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <div
        className="rbt-program-area rbt-section-gapTop"
        style={{ backgroundColor: "#f4f4f4" }}
      >
        <div className="container">
          `
          <div className="row g-5 align-items-end">
            <div className="col-lg-12 col-md-12 col-12 mt-0 mb-5">
              <div className="section-title text-center">
                <h2 className="title">Contact Us</h2>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-12">
              <ContactForm />
              <br />
              <br />
              {/* <ContactUs /> */}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="rbt-course-area rbt-section-gapTop rbt-section-gapBottom bg-color-white"
        id="course"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Top Courses</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="/courses">
                  View All<i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>

          <div className="row g-5">
            <CardThree/>
          </div>
        </div>
      </div> */}

      {/* <div
        className="rbt-rbt-blog-area rbt-section-gapTop bg-color-white"
        id="blog"
      >
        <div className="container">
          <div className="row g-5 align-items-end mb--30">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Our Research</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="/blog-list">
                  Browse gd-college Post<i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <BlogGrid isPagination={false} top={false} start={0} end={3} />
        </div>
      </div>

      <div className="rbt-event-area bg-color-white rbt-section-gapTop">
        <div className="container">
          <div className="row g-5 align-items-end mb--60">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="section-title text-start">
                <h2 className="title">Our Events</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="load-more-btn text-start text-lg-end">
                <Link className="rbt-btn-link" href="#">
                  University Upcoming Events
                  <i className="feather-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
          <Events
            start={0}
            end={4}
            isPagination={false}
            parentClass="card-list-2 event-list-card"
            childClass="col-lg-6 col-md-6 col-12"
            getEvents={EventData}
          />
        </div>
      </div> */}
      {/* <div
        className="rbt-testimonial-area bg-color-white rbt-section-gap overflow-hidden"
        id="testimonial"
      >
        <div className="container">
          <Course title="University Tuition & Fees" />
        </div>
      </div> */}

      {/* <div className="rbt-testimonial-area bg-color-light rbt-section-gap overflow-hidden"> */}
      {/* <div className="wrapper mb--60">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <span className="subtitle" style={{background: 'black', color: 'white'}}>
                    EDUCATION FOR EVERYONE
                  </span>
                  <h2 className="title">
                    Testimonials
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <Testimonial /> */}
      {/* </div> */}
      {/* <div className="rbt-gallery-area">
        <Gallery />
      </div> */}
      {/* <div className="rbt-brand-area bg-color-white rbt-section-gap">
        <div className="container">
          <div className="row align-items-center g-5">
            <BrandOne />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default UniversityClassic;
