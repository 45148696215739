import { useEffect } from "react";

const HubSpotForm = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "68996e19-baaa-4c04-944d-d08e65d2b87e", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};
export default HubSpotForm;

export const HubSpotFormESthDip = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "e19b6baf-e97a-4496-85da-46333336e874", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};

export const HubSpotFormClinicalEsth = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "5fbe1808-16fc-4f4d-ad4f-2ed59cc76209", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};

export const HubSpotFormAdvEsth = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "fb6b1d0e-0e12-48b2-b962-45fd7b96207a", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};

export const HubSpotFormMakeUpArt = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "72636662-7535-46db-8ed0-95293d907343", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};

export const HubSpotFormNailArt = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "54c94f5a-84c1-46c2-92f1-6261d16c70fc", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};

export const HubSpotFormMassage = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js"; // HubSpot Forms API URL
    script.async = true;
    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "46151403", // Replace with your HubSpot Portal ID
          formId: "4c9a251c-e85f-49f0-8de5-be6a2a1b8c7f", // Replace with your HubSpot Form ID
          target: "#hubspotForm",
        });
      }
    };
    document.body.appendChild(script);
  }, []);
  return <div id="hubspotForm"></div>;
};
