

// import '../../public/scss/elements/_contact-email.scss';

import sgMail from "@sendgrid/mail";
import { useState } from "react";
import HubSpotForm from './Hubspot';

// sgMail.setApiKey('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');
sgMail.setApiKey(
  "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"
);

const ContactForm = ({ gap }) => {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [contactPhone, setContactPhone] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isEmail, setIsEmail] = useState(false);
  const [isPhone, setIsPhone] = useState(false);

  // Handle checkbox change
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const EmailSentPopup = ({ show, onClose, children }) => {
    if (!show) {
      return null;
    }

    return (
      <div className="popup-overlay">
        <div className="popup-content">
          <button onClick={onClose} className="popup-close-button">
            X
          </button>
          {children}
        </div>
      </div>
    );
  };

  const submitForm = async (e) => {
    // document.getElementById('contact-form').addEventListener('submit', async function(event) {
    e.preventDefault(); // Prevent default form submission
    let newPhone = `+1${contactPhone}`

    if (contactEmail) {
      setIsEmail(false);
    } else {
      setIsEmail(true);
    }
    setContactPhone(newPhone)

    if (contactPhone) {
      setIsPhone(false);
    } else {
      setIsPhone(true);
    }

    if (contactPhone && contactEmail) {
      const content = {
        to: "website@gdcollege.ca", // Your email where you want to receive messages
        from: "info@gdcollege.ca", // Use a verified SendGrid sender email
        subject: `${contactName} | Website Contact-Us Enquiry`,
        text: `New email from ${contactName} <${contactEmail}>: ${message}`,
        replyTo: contactEmail,
        html: `
          <table style="border: '1px solid black'; border-collapse: 'collapse'; color: 'black'">
            <tr>
                <td style="border: '1px solid black'; padding: '8px';"}>Name</td>
                <td style="border: '1px solid black'; padding: '8px'">${contactName}</td>
            </tr>
            <tr>
                <td style="border: '1px solid black'; padding: '8px';">Email</td>
                <td style="border: '1px solid black'; padding: '8px';">${contactEmail}</td>
            </tr>
            <tr>
                <td style="border: '1px solid black'; padding: '8px';">Phone</td>
                <td style="border: '1px solid black'; padding: '8px';">${
                  contactPhone
                }</td>
            </tr>
            <tr>
                <td style="border: '1px solid black'; padding: '8px';">Query</td>
                <td style="border: '1px solid black'; padding: '8px';">${message}</td>
            </tr>
          </table>`,
      };

      try {
        const response = await fetch("/api/send-email", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(content),
        });

        if (response.ok) {
          console.log("Email sent successfully");
          setIsShowDetails(true);
          setContactName("");
          setContactEmail("");
          setContactPhone("");
          setMessage("");
          setSubject("");
        } else {
          setIsShowDetails(false);
          console.error("Error sending email");
        }
      } catch (error) {
        setIsShowDetails(false);
        console.error("Error sending message:", error);
        window.alert("Message not sent.");
      }
    }
  };

  return (
    <>
      {isShowDetails ? (
        <EmailSentPopup
          show={isShowDetails}
          onClose={() => setIsShowDetails(false)}
        >
          <h4 style={{ padding: "10px" }}>Thank you for your submission. <br/>Our coordinator will be in touch soon.</h4>
        </EmailSentPopup>
      ) : (
        ""
      )}
      <div className={`rbt-contact-address ${gap}`}>
      
        <div className="container">
          <div className="row g-5">

            <div className="col-lg-6">
              <div className="rbt-contact-form contact-form-style-1 max-width-auto">
                <div className="section-title text-center">
                  {/* <span className="subtitle" style={{backgroundColor: '#CBE4E8', color: '#000'}}>
                    EDUCATION FOR EVERYONE
                  </span> */}
                </div>
                {/* <h3 className="title text-left">
                Submit your details and we&apos;ll be in touch shortly
                </h3> */}
                <HubSpotForm/>
                {/* <for */}
              </div>
            </div>

            <div className="col-lg-6">
            <div style={{paddingBottom: "8%"}}>
              <div className="icon" style={{padding:"2%", color:'black', verticalAlign: "bottom", fontSize: "xx-large"}}>
                  <i className="feather-headphones"></i> <span style={{color: "black", textDecoration: "underline", fontSize: "large", paddingLeft: "10px", verticalAlign: "middle"}}>+1 587-349-1110</span>
              </div>
              <div className="icon" style={{padding:"2%", color:'black', verticalAlign: "bottom", fontSize: "xx-large"}}>
                  <i className="feather-mail"></i> <span style={{color: "black", textDecoration: "underline", fontSize: "large", paddingLeft: "10px", verticalAlign: "middle"}}>info@gdcollege.ca</span>
              </div>
              <div className="icon" style={{padding:"2%", color:'black', verticalAlign: "bottom", fontSize: "xx-large"}}>
                  <i className="feather-map-pin"></i> <span style={{color: "black", textDecoration: "underline", fontSize: "large", paddingLeft: "10px", verticalAlign: "middle"}}>#108, 1935- 27 ave NE,  Calgary, AB T2E 7E4, Canada</span>
              </div>
              </div>
              <div className="thumbnail">
                {/* <Image
                  className="w-100 radius-6"
                  src={img}
                  alt="Contact Images"
                /> */}
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5013.483516680688!2d-114.012197!3d51.076317!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716521f3f367eb%3A0xb0aa546951b3417f!2sGD%20College%20%E2%80%94Cosmetology%20School%20in%20Calgary!5e0!3m2!1sen!2sus!4v1725380792597!5m2!1sen!2sus"  width="100%" height="490" style={{border:'0'}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
               </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
